// "use client";
import { ThemesG } from "@/types/design-system-types";
import { TrustPilotProps } from "@/types/trustpilot";
import { cn, getRouteDetails } from "@/utils/helpers";
import Page from "@/utils/system/page";
import { Entry } from "contentful";
import { useRouter } from "next/router";
import { useEffect, useRef } from "react";

// Extend the TrustPilotProps type to include className
type TrustpilotMicroT = {
  options?: TrustPilotProps;
  theme?: ThemesG;
};

type TrustpilotMicroThemes = "light" | "dark";

const TrustpilotMicro = ({
  data,
  debug,
  className,
  options = data.fields.options,

  // Controlled by parent
  theme = data.fields.theme ?? "white-100",
  ...props
}: {
  data: Entry<TrustpilotMicroT>;
  debug?: any;
  className?: string;
} & TrustpilotMicroT) => {
  const ref = useRef(null);
  const trustpilotTheme: TrustpilotMicroThemes = theme
    ? Page.getThemeBrightness(theme)
    : options?.theme || "light";

  const router = useRouter();

  const { locale } = getRouteDetails(router);

  useEffect(() => {
    // Force trustpilot to load on page view
    // https://support.trustpilot.com/hc/en-us/articles/115011421468--Add-a-TrustBox-widget-to-a-single-page-application
    if ((window as any).Trustpilot) {
      (window as any).Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <span className="relative mb-4 block w-fit last:mb-0">
      {debug}

      <span
        ref={ref}
        className={cn("trustpilot-widget relative -ml-7 block ", [className])}
        data-locale={locale || "en-GB"}
        data-template-id={options?.templateId || "5419b637fa0340045cd0c936"}
        data-businessunit-id={options?.businessunitId || "58244f650000ff0005974e81"}
        data-style-height={options?.styleHeight || "20px"}
        data-style-width={options?.styleWidth || "250px"}
        data-style-align={options?.styleAlign || "left"}
        data-theme={trustpilotTheme}
        {...props}
      >
        <span className="ml-7 flex w-auto items-center gap-x-2 max-w-40 rounded-lg border border-[#00B67A] px-2 py-1 text-sm">
          <svg
            className="h-4 w-fit"
            viewBox="0 0 31 31"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="#00B67A"
              d="M30.141707 11.07005H18.63164L15.076408.177071l-3.566342 10.892977L0 11.059002l9.321376 6.739063-3.566343 10.88193 9.321375-6.728016 9.310266 6.728016-3.555233-10.88193 9.310266-6.728016z"
            ></path>
            <path
              fill="#005128"
              d="M21.631369 20.26169l-.799928-2.463625-5.755033 4.153914z"
            ></path>
          </svg>
          <span>Trustpilot micro placeholder</span>
        </span>
      </span>
    </span>
  );
};

export default TrustpilotMicro;
